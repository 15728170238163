import React, {Component} from "react";

class AppLink extends Component {

  render() {
    const {url, text, show, readonly, title} = this.props;

    if(!show) {
      return null
    }

    if (readonly) {
        return (
            <div className="app-link">
                <h3>
                    {text}
                </h3>
                <p>
                    Du har adgang til dette undersystem, men det er ikke i drift endnu.
                </p>
            </div>
        )
    }

    return (
        <div className="app-link">
          <h3>
            <a href={url} title={title}>{text}</a>
          </h3>
        </div>
    )
  }
}

export default AppLink;