import React, {Component} from "react";

class OrganisationAlert extends Component {

    render() {
        const {error} = this.props;
            return (
                <div id="alert-fejl" className="alert alert-danger">
                    <table className="noborder">
                        <tbody>
                        <tr className="noborder">
                            <td className="noborder">{error}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            );
        }
}

export default OrganisationAlert;