import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import ForsideConnector from "./containers/ForsideConnector";
import LoginConnector from "./containers/LoginConnector";
import constants from "./util/constants";
import VersionConnector from "./containers/VersionConnector";

class Content extends Component {

    render() {

    return (
      // Login nulstiller token og viderestiller til BE-login. Dette er nødvendigt ved relogin,
      // da det nye token i query param ikke vil overskrive et token i session storage.
      // Logout viderestiller direkte til BE-logout, som vil viderestille tilbage til /?logout.
      // Hermed er det kun tilslutning, der behøver at kende til sin backend.
      <Switch>
        <Route exact path="/" component={ForsideConnector} />
        <Route exact path={constants.urlLogin} component={LoginConnector} />
        <Route exact path={constants.urlVersion} component={VersionConnector} />
        <Route exact path={constants.urlLogout} component={() => {
          window.location.replace(constants.urlBELogout);
          return null;
        }} />
      </Switch>
    )
  }
}

export default Content;
