import {Component} from 'react'
import constants from "../util/constants";

class LoginMain extends Component {

  componentDidMount() {

    const {loginClear} = this.props;
    loginClear();
    // replace: /login skal ikke med i history.
    window.location.replace(constants.urlBELogin);
  }

  render() {
    return "";
  }
}

export default LoginMain;
