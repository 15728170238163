import React, {Component} from 'react';
import Header from './util/Header.js';
import {Footer} from 'tilslutning-frontend-common';
import Content from "./Content";

class App extends Component {

  render() {
    return (
      <div className="stil-site clearfix">
        <Header />
        <Content />
        <Footer />
      </div>
    );
  }
}

export default App;
