import React, {Component} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import * as DOMPurify from 'dompurify';
import {formatDate} from "tilslutning-frontend-common";

class NotifikationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visNotifikationModel: true,
        };
        this.lukNotifikationModel = this.lukNotifikationModel.bind(this);
    }

    lukNotifikationModel() {
        this.setState({visNotifikationModel: false});
    };

    render() {
        const {notifikationer} = this.props;
        const {visNotifikationModel} = this.state;

        const notifikationBox = {
            listStyleType: 'none',
            textAlign: 'left',
            border: '1px solid black',
            marginBottom: '10px',
            padding: '5px',
        }

        if (notifikationer === undefined || notifikationer.length === 0) {
            return null;
        }

        return (
            <Modal isOpen={visNotifikationModel} toggle={this.lukNotifikationModel} onClosed={this.lukNotifikationModel}
                   className="notifikation-historik-modal">
                <ModalHeader>
                    <Button type="button" className="close" data-dismiss="modal" onClick={this.lukNotifikationModel}
                            aria-hidden="true"
                            title="Lukker dialogen">×</Button>
                    <h2>Notifikationer</h2>
                </ModalHeader>
                <ModalBody>
                    {
                        notifikationer.sort((a, b) => new Date(b.oprettetTs) - new Date(a.oprettetTs)).map((notifikation, index) => (
                            <p key={index}>
                                <div style={notifikationBox}>
                                    <strong>{formatDate(notifikation.oprettetTs)}: </strong>
                                    <br/>
                                    <span
                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(notifikation.tekst)}}/>
                                </div>
                            </p>
                        ))
                    }
                </ModalBody>
                <ModalFooter>
                    <div className="buttongroup">
                        <button id="udbyder-close-button" className="hand margleft10 stil-primary-button button"
                                onClick={this.lukNotifikationModel} title="Lukker dialogen">
                            Luk
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

export default NotifikationModal;
