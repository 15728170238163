import React, {Component} from 'react'
import {Link} from 'react-router-dom';
import constants from "../util/constants";
import {LogoutBar} from 'tilslutning-frontend-common';
import NotifikationModal from "../modals/NotifikationModal";

class LoginBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            loggedIn,
            bruger,
            openSelectOrganisationModal,
            alerts,
            notifikationer
        } = this.props;

        if (loggedIn && alerts.fejl.length <= 0) {

            if (!bruger.organisation) {
                openSelectOrganisationModal();
                return null;
            }
            if (bruger.flereOrganisationer) {
                return (
                    <div>
                        <nav className="stil-topmenu">
                            <ul className="topmenuRight">
                                <li>
                                    <LogoutBar notifikationer={notifikationer}/>
                                </li>
                            </ul>
                        </nav>
                        <div className="everythingOnOneLine">
                            <span>Logget på som {bruger.navn}, </span>
                            <a id="organisation-skift-link" onClick={openSelectOrganisationModal} className="hand"
                               title="Skift organisation">
                                <img src="img/caret-right.svg"
                                     alt=">"/>{`${bruger.organisation.navn} (${bruger.organisation.kode})`}
                            </a>
                        </div>
                        <NotifikationModal notifikationer={notifikationer === undefined ? [] : notifikationer.filter(notifikation => !notifikation.erSet)}/>
                    </div>
                );
            }
            return (
                <div>
                    <nav className="stil-topmenu">
                        <ul className="topmenuRight">
                            <li>
                                <LogoutBar notifikationer={notifikationer}/>
                            </li>
                        </ul>
                    </nav>
                    <div className="everythingOnOneLine">
                        <span>Logget på som {bruger.navn}, </span><span>{bruger.organisation.navn}</span>
                    </div>
                    <NotifikationModal notifikationer={notifikationer === undefined ? [] : notifikationer.filter(notifikation => !notifikation.erSet)}/>
                </div>
            );
        }
        if (alerts.fejl.length > 0) {
            return (
                <nav className="stil-topmenu">
                    <ul className="topmenuRight">
                        <li>
                            <LogoutBar notifikationer={notifikationer}/>
                        </li>
                    </ul>
                </nav>
            )
        }

        return (
            <div>
                <nav className="stil-topmenu">
                    <ul className="topmenuLeft">
                    </ul>
                    <ul className="topmenuRight">
                        <li>
                            <p className="stil-logged-in-user">
                                <div className={"button-container"}>
                                <Link id="button-login" to={constants.urlLogin}><span
                                    title="Log ind i Tilslutning">Log ind</span></Link>
                                </div>
                            </p>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default LoginBar;
