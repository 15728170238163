import React, {Component} from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

class LoginModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }

  render() {
    const {visModal, lukModal, login, basicLoginFejl} = this.props;

    if (!visModal)
      return null;


    const loginDisabled = this.state.username === "" || this.state.password === "";
    return (
      <Modal isOpen={visModal} toggle={lukModal} onClosed={lukModal}>
        <ModalHeader>
          <Button type="button" className="close" data-dismiss="modal" onClick={lukModal} aria-hidden="true">×</Button>
          <p>Login</p>
        </ModalHeader>
        <ModalBody>
          { basicLoginFejl ?
              <div id="alert-info" className="alert alert-info">
                <table className="noborder">
                  <tbody>
                    <tr className="noborder">
                      <td className="noborder">{basicLoginFejl}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              : null
          }

          <input type="text" id="login-username" placeholder="Brugernavn" className="login-input" value={this.state.username}
                 onChange={(event) => this.setState({ username: event.target.value })}/>

          <input type="password" id="login-password" placeholder="Kodeord" className="login-input" value={this.state.password}
                 onChange={(event) => this.setState({ password: event.target.value })}/>
        </ModalBody>
        <ModalFooter>
          <div className="buttongroup">
            <button className="button stil-primary-button" onClick={lukModal} title="Luk modalen">Annuller</button>
            <button className={loginDisabled ? "margleft10 button stil-disabled-button" : "margleft10 button stil-primary-button"}
                    disabled={loginDisabled} onClick={() => login(this.state.username, this.state.password)} title="Log ind">Log ind</button>
          </div>
        </ModalFooter>

      </Modal>
    )
  }
}
export default LoginModal
