import constants from "../util/constants";

export function forsideReducer(state = [], action) {

  switch(action.type) {

    case constants.NULSTIL_ALERTS:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_nulstiller_alerts,
        alerts: {fejl: [], info: []}
      };

    case constants.SELECT_ORGANISATION:
      return {
        ...state,
        previousType: action.type,
        showSelectOrganisationModal: false
      };

    case constants.OPEN_ORGANISATION_MODAL:
      return {
        ...state,
        previousType: action.type,
        showSelectOrganisationModal: true
      };

    case constants.VIS_SESSION_ADVARSEL:
      return {
        ...state,
        previousType: action.type,
        showSessionAlertModal: true
      };

    case constants.SKJUL_SESSION_ADVARSEL:
      return {
        ...state,
        previousType: action.type,
        showSessionAlertModal: false
      };

    case constants.VIS_LOGIN_MODAL:
      return {
        ...state,
        previousType: action.type,
        showLoginModal: true
      };

    case constants.SKJUL_LOGIN_MODAL:
      return {
        ...state,
        previousType: action.type,
        showLoginModal: false,
        basicLoginFejl: null
      };

    case constants.BASIC_LOGIN_SUCCESS:
      return {
        ...state,
        previousType: action.type,
        showLoginModal: false,
        basicLoginFejl: null
      };

    case constants.BASIC_LOGIN_FEJL:
      return {
        ...state,
        previousType: action.type,
        basicLoginFejl: action.basicLoginFejl
      };

    case constants.BEKRAEFT_NOTIFIKATIONSEMAIL_SUCCESS:
      return {...state, alerts: action.alerts};

    case constants.BEKRAEFT_NOTIFIKATIONSEMAIL_FEJL:
      return {...state, alerts: action.alerts};

    case constants.FORNY_KONTAKTPERSON_SUCCESS:
      return {...state, alerts: action.alerts};

    case constants.FORNY_KONTAKTPERSON_FEJL:
      return {...state, alerts: action.alerts};

    case constants.SET_SELECT_ORGANISATION_FEJL:
      return {...state, previousType: action.type, alerts: action.alerts };

    default:
      return {...state, previousType: action.type };
  }
}

