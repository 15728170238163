import React, {Component} from 'react'
import {AlertBar, constants as commonConstants} from 'tilslutning-frontend-common';
import constants, {STIL_CVR} from '../util/constants';
import AppLink from "./AppLink";
import AppBox from "./AppBox";

class AppLinks extends Component {
    render() { // NOSONAR
        const {forside, login} = this.props;

        const isLoggedIn = login !== undefined && login.bruger.roller !== null;
        const brugersCvrErOrganisationsCvr = isLoggedIn && login.bruger.organisation != null && login.bruger.organisation.cvr && login.bruger.organisation.cvr.toString() === login.bruger.stamdataCvr;
        const erStil = isLoggedIn && login.bruger.roller.includes(commonConstants.roller.stilRolle)
        const erSupport = isLoggedIn && login.bruger.roller.includes(commonConstants.roller.supportRolle)
        const erEkspertSupport = isLoggedIn && login.bruger.roller.includes(commonConstants.roller.ekspertSupportRolle)

        const adgangTilUdbydersystem = isLoggedIn && login.bruger.organisation && login.bruger.organisation.type === "UDBYDER"
            && (login.bruger.roller.includes(commonConstants.roller.udbyderRolle) || erSupport || erEkspertSupport);
        const adgangTilStilservice = erStil && brugersCvrErOrganisationsCvr;
        const adgangTilDataadgang = isLoggedIn && login.bruger.organisation && ["DAGTILBUD", "REST", "INSTITUTION"].includes(login.bruger.organisation.type)
            && (login.bruger.roller.includes(commonConstants.roller.institutionRolle) || erSupport || erEkspertSupport);
        const adgangTilRestInstitution = (erSupport || erEkspertSupport) && brugersCvrErOrganisationsCvr;
        const adgangTilUdbyder = (erSupport || erEkspertSupport) && brugersCvrErOrganisationsCvr;
        const adgangTilSupportvaerktoej = (erSupport || erEkspertSupport) && brugersCvrErOrganisationsCvr;
        const erPassiveret = isLoggedIn && login.bruger.organisation !== null && login.bruger.organisation.erPassiveret;

        return (
            <div className="app-links-container">
                <div id="forsidemain">
                    <AlertBar alerts={forside.alerts}/>

                    <br/>
                    {erPassiveret ? (erStil ?
                            <div className="app-box-centered">
                                <p>Denne udbyder er passiveret, og du har derfor ikke mulighed for oprette systemer
                                    eller se systemlogin.</p>
                                <p>Udbyderen er passiveret med følgende bemærkning:</p>
                                <p>{login.bruger.organisation.passiveretKommentar}</p>
                            </div>
                            :
                            <div className="app-box-centered">
                                <p>Du er logget ind som en passiveret udbyder og har derfor ikke mulighed for at
                                    administrere
                                    dine udbydersystemer i Tilslutning.</p>
                                <p>Udbyderen er passiveret med følgende bemærkning:</p>
                                <p>{login.bruger.organisation.passiveretKommentar}</p>
                                <br/>
                                <p>Hvis du har brug for hjælp, eller mener der er tale om en fejl, bedes du kontakte
                                    supporten ved at oprette en supportsag.</p>
                                <a href="https://jira.stil.dk/servicedesk/customer/portal/7/create/173"
                                   title="Kontakt supporten">Kontakt supporten</a>
                            </div>
                    ) : null}

                    <div className="datadisplay app-links">
                        <AppLink readonly={login.bruger.readonly && login.bruger.stamdataCvr !== STIL_CVR}
                                 show={adgangTilUdbydersystem} url={constants.urlUdbyderSystemer}
                                 text={"Udbydersystemadministration"} title="Åbn Udbydersystemadministrationen"/>
                    </div>
                    {
                        adgangTilUdbydersystem ?
                            <div className="app-boxes">
                                <AppBox title="Tilslutningsaftale med STIL om adgang til webservices" elements={[
                                    {
                                        text: "Anmod om at bruge ny webservice",
                                        url: `/udbydersystemadm/stilservices`,
                                        title: ""
                                    },
                                    {
                                        text: "Se oversigt over de webservices, som I har adgang til",
                                        url: `/udbydersystemadm/tilslutninger`,
                                        title: ""
                                    },
                                    {
                                        text: "Vejledning: Sådan får du adgang til en STIL-webservice",
                                        url: "https://viden.stil.dk/pages/viewpage.action?pageId=49971412",
                                        title: "",
                                        nyTab: true
                                    }
                                ]}/>
                                <AppBox title="Dataadgange til institutioner" elements={[
                                    {text: "Anmod om dataadgang", url: `/udbydersystemadm/dataaftaler`, title: ""},
                                    {
                                        text: "Vejledning: Sådan laver du en anmodning om dataadgang",
                                        url: "https://viden.stil.dk/display/STILVIDENOFFENTLIG/Anmodning+om+dataadgang",
                                        title: "",
                                        nyTab: true
                                    }
                                ]}/>
                                <AppBox title="Administrer tilslutninger" elements={[
                                    {text: "Glemt password", url: `/udbydersystemadm/akkreditiver`, title: ""},
                                    {text: "Opret nyt system", url: `/udbydersystemadm/vedligehold`, title: ""}
                                ]}/>
                            </div> : null
                    }
                    <div className="datadisplay app-links">
                        <AppLink readonly={login.bruger.readonly && login.bruger.stamdataCvr !== STIL_CVR}
                                 show={adgangTilStilservice} url={constants.urlStilServices}
                                 text={"Stilserviceadministration"} title="Åbn Stilserviceadministrationen"/>
                        <AppLink readonly={login.bruger.readonly && login.bruger.stamdataCvr !== STIL_CVR}
                                 show={adgangTilDataadgang} url={constants.urlDataadgange}
                                 text={"Dataadgangadministration"} title="Åbn Dataadgangadministrationen"/>
                        <AppLink readonly={login.bruger.readonly && login.bruger.stamdataCvr !== STIL_CVR}
                                 show={adgangTilRestInstitution} url={constants.urlRestinstitution}
                                 text={"Restinstitutionadministration"} title="Åbn Restinstitutionadministrationen"/>
                        <AppLink readonly={login.bruger.readonly && login.bruger.stamdataCvr !== STIL_CVR}
                                 show={adgangTilUdbyder} url={constants.urlUdbyder} text={"Udbyderadministration"}
                                 title="Åbn Udbyderadministrationen"/>
                        <AppLink readonly={login.bruger.readonly && login.bruger.stamdataCvr !== STIL_CVR}
                                 show={adgangTilSupportvaerktoej} url={constants.urlSupportvaerktoej}
                                 text={"Supportværktøj"} title="Åbn Supportværktøjet"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default AppLinks;
