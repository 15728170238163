import constants from "../util/constants";

export function versionReducer(state = [], action) {

  switch (action.type) {
    case constants.GET_FRONTEND_VERSION_SUCCESS:
      return {...state, forsideFrontendVersion: action.version};

    case constants.GET_BACKEND_VERSION_SUCCESS:
      return {...state, forsideBackendVersion: action.version};

    case constants.GET_UDBYDERSYSTEMADM_FRONTEND_VERSION_SUCCESS:
      return {...state, udbydersystemadmFrontendVersion: action.version};

    case constants.GET_UDBYDERSYSTEMADM_BACKEND_VERSION_SUCCESS:
      return {...state, udbydersystemadmBackendVersion: action.version};

    case constants.GET_STILSERVICEADM_FRONTEND_VERSION_SUCCESS:
      return {...state, stilserviceadmFrontendVersion: action.version};

    case constants.GET_STILSERVICEADM_BACKEND_VERSION_SUCCESS:
      return {...state, stilserviceadmBackendVersion: action.version};

    case constants.GET_DATAADGANGADM_FRONTEND_VERSION_SUCCESS:
      return {...state, dataadgangadmFrontendVersion: action.version};

    case constants.GET_DATAADGANGADM_BACKEND_VERSION_SUCCESS:
      return {...state, dataadgangadmBackendVersion: action.version};

    case constants.GET_RESTINSTITUTIONADM_FRONTEND_VERSION_SUCCESS:
      return {...state, restinstitutionadmFrontendVersion: action.version};

    case constants.GET_RESTINSTITUTIONADM_BACKEND_VERSION_SUCCESS:
      return {...state, restinstitutionadmBackendVersion: action.version};

    case constants.GET_SUPPORTVAERKTOEJ_FRONTEND_VERSION_SUCCESS:
      return {...state, supportvaerktoejFrontendVersion: action.version};

    case constants.GET_SUPPORTVAERKTOEJ_BACKEND_VERSION_SUCCESS:
      return {...state, supportvaerktoejBackendVersion: action.version};

    default:
      return state;
  }
}
