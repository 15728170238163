export function isEmpty(obj) {
  return obj === null || obj === undefined;
}
export function isEmptyStr(obj) {
  return obj === null || obj === undefined || obj === "" || obj.trim() === "";
}
export function isEmptyArray(obj) {
  return obj === null || obj === undefined || obj.length === 0;
}
export function formatDate(ldt) {
  if (ldt === null) return "";
  var date = new Date(ldt);
  return fill(date.getDate()) + "-" + fill(date.getMonth() + 1) + "-" + date.getFullYear() + " " + fill(date.getHours()) + ":" + fill(date.getMinutes());
}

function fill(str) {
  if ("".concat(str).length === 1) return "0" + str;
  return str;
}