import 'whatwg-fetch';
import constants from "../util/constants";
import {fetchResponse, fetchJson} from 'tilslutning-frontend-common';

export function loginClear() {
    return {type: constants.LOGIN_CLEAR}
}

export function selectOrganisation(organisation) {
    return (dispatch, getState) => {
        fetchJson(constants.apiSkiftOrganisation, "POST", organisation, dispatch)
            .then(json => {
                dispatch(selectOrganisationSuccess(json));
            })
            .catch(errorResponse => {
                dispatch(selectOrganisationFejl([`Kunne ikke skifte organisationen. Log ud og prøv igen.`]));
            });
    };
}

export function hentNotifikationer() {
    return (dispatch, getState) => {
        fetchJson(constants.apiHentNotifikationer, "GET", undefined, dispatch)
            .then(json => dispatch(hentNotifikationerSuccess(json)))
            .catch(errorResponse => dispatch(hentNotifikationerFejl(['Kunne ikke hente notifikationer.'])));
    }
}

function hentNotifikationerSuccess(notifikationer) {
    return {type: constants.HENT_NOTIFIKATIONER_SUCCESS, notifikationer: notifikationer}
}

function hentNotifikationerFejl(errors) {
    return {type: constants.HENT_NOTIFIKATIONER_FEJL, alerts: {fejl: errors, info: []}}
}

export function openSelectOrganisationModal() {
    return {type: constants.OPEN_ORGANISATION_MODAL}
}

function selectOrganisationSuccess(bruger) {
    return {type: constants.SELECT_ORGANISATION, bruger: bruger}
}

function selectOrganisationFejl(errors) {
    return {type: constants.SET_SELECT_ORGANISATION_FEJL, alerts: {fejl: errors, info: []}}
}

export function hentInstitutioner() {
    return (dispatch, getState) => {
        fetchJson(constants.apiHentOrganisationerInstitutioner, "GET", undefined, dispatch)
            .then(json => dispatch(hentInstitutionerSuccess(json)))
            .catch(errorResponse => dispatch(hentInstitutionerFejl([`Kunne ikke hente institutioner.`])));
    }
}

function hentInstitutionerSuccess(institutioner) {
    return {type: constants.HENT_INSTITUTIONER_SUCCESS, institutioner: institutioner}
}

function hentInstitutionerFejl(errors) {
    return {type: constants.HENT_INSTITUTIONER_FEJL, alerts: {fejl: errors, info: []}}
}

export function hentRestOrganisationer() {
    return (dispatch, getState) => {
        fetchJson(constants.apiHentRestInstitutioner, "GET", undefined, dispatch)
            .then(json => dispatch(hentRestOrganisationerSuccess(json)))
            .catch(errorResponse => dispatch(hentRestOrganisationerFejl([`Kunne ikke hente restinstitutioner.`])));
    };
}

function hentRestOrganisationerSuccess(restOrganisationer) {
    return {type: constants.HENT_REST_ORGANISATIONER_SUCCESS, restOrganisationer: restOrganisationer}
}

function hentRestOrganisationerFejl(errors) {
    return {type: constants.HENT_REST_ORGANISATIONER_FEJL, alerts: {fejl: errors, info: []}}
}

export function hentUdbydere() {
    return (dispatch, getState) => {
        fetchJson(constants.apiHentOrganisationerUdbydere, "GET", undefined, dispatch)
            .then(json => dispatch(hentUdbydereSuccess(json)))
            .catch(errorResponse => dispatch(hentUdbydereFejl([`Kunne ikke hente udbydere.`])));
    };
}

function hentUdbydereSuccess(udbydere) {
    return {type: constants.HENT_UDBYDERE_SUCCESS, udbydere: udbydere}
}

function hentUdbydereFejl(errors) {
    return {type: constants.HENT_UDBYDERE_FEJL, alerts: {fejl: errors, info: []}}
}

export function hentDagtilbud() {
    return (dispatch, getState) => {
        fetchJson(constants.apiHentOrganisationerDagtilbud, "GET", undefined, dispatch)
            .then(json => dispatch(hentDagtilbudSuccess(json)))
            .catch(errorResponse => dispatch(hentDagtilbudFejl([`Kunne ikke hente dagtilbud.`])));
    };
}

function hentDagtilbudSuccess(dagtilbud) {
    return {type: constants.HENT_DAGTILBUD_SUCCESS, dagtilbud: dagtilbud}
}

function hentDagtilbudFejl(errors) {
    return {type: constants.HENT_DAGTILBUD_FEJL, alerts: {fejl: errors, info: []}}
}

export function visSessionAlert() {
    return {type: constants.VIS_SESSION_ADVARSEL}
}

export function skjulSessionAlert() {
    return {type: constants.SKJUL_SESSION_ADVARSEL}
}

export function visLoginModal() {
    return {type: constants.VIS_LOGIN_MODAL}
}

export function skjulLoginModal() {
    return {type: constants.SKJUL_LOGIN_MODAL}
}

export function basicLogin(username, password) {
    return (dispatch, getState) => {
        fetchJson(constants.apiBasicLogin, "POST", {username, password}, dispatch)
            .then(json => dispatch(basicLoginSuccess(json)))
            .catch(errorResponse => dispatch(basicLoginFejl([`Forkert brugernavn eller kodeord.`])));
    };
}

function basicLoginSuccess(bruger) {
    return {type: constants.BASIC_LOGIN_SUCCESS, bruger: bruger}
}

function basicLoginFejl(error) {
    return {type: constants.BASIC_LOGIN_FEJL, basicLoginFejl: error}
}

export function bekraeftNotifikationsemail(uuid) {
    return (dispatch, getState) => {
        const url = `${constants.apiBekraeftNotifikationsemail}?uuid=${uuid}`;
        fetchResponse(url,  "POST", undefined, dispatch).then((response) => {
            if (!response.ok)
                throw response;
            return response.text()
        }).then((text) => {
            dispatch(bekraeftNotifikationsemailSuccess(text));
        }).catch((errorResponse) => {
            if (errorResponse.status === 404) {
                dispatch(bekraeftNotifikationsemailFejl([`Kunne ikke finde en notifikationsemail med UUID ${uuid}`]));
            } else {
                dispatch(bekraeftNotifikationsemailFejl(["Der er sket en fejl ved bekræftelse af mailadressen."]));
            }
        });
    };
}

function bekraeftNotifikationsemailSuccess(info) {
    return {type: constants.BEKRAEFT_NOTIFIKATIONSEMAIL_SUCCESS, alerts: {fejl: [], info: [info]}}
}

function bekraeftNotifikationsemailFejl(errors) {
    return {type: constants.BEKRAEFT_NOTIFIKATIONSEMAIL_FEJL, alerts: {fejl: errors, info: []}}
}

export function fornyKontaktperson(uuid) {
    return (dispatch, getState) => {
        const url = `${constants.apiFornyKontaktperson}?uuid=${uuid}`;
        fetchResponse(url, "POST", undefined, dispatch).then((response) => {
            if (!response.ok)
                throw response;
            return response.text()
        }).then((text) => {
            dispatch(fornyKontaktpersonSuccess(text));
        }).catch((errorResponse) => {
            if (errorResponse.status === 404) {
                dispatch(fornyKontaktpersonFejl([`Kunne ikke finde en kontakperson med UUID ${uuid}`]));
            } else {
            dispatch(fornyKontaktpersonFejl(["Der er sket en fejl ved fornyelse af kontaktpersonen."]));
            }
        });
    };
}

function fornyKontaktpersonSuccess(info) {
    return {type: constants.FORNY_KONTAKTPERSON_SUCCESS, alerts: {fejl: [], info: [info]}}
}

function fornyKontaktpersonFejl(errors) {
    return {type: constants.FORNY_KONTAKTPERSON_FEJL, alerts: {fejl: errors, info: []}}
}

export function fornyJwt() {
    return (dispatch, getState) => {
        fetchJson(constants.apiFornyJwt, "GET", undefined, dispatch)
            .then(json => dispatch(fornyJwtSuccess(json)))
            .catch(errorResponse => {
                if (errorResponse.status === 401)
                    dispatch(fornyJwtFejl(["401"]));
                else
                    dispatch(fornyJwtFejl(['Kunne ikke forny JWT.']));
            });
    };
}

function fornyJwtSuccess(bruger) {
    return {type: constants.FORNY_JWT_SUCCESS, bruger: bruger}
}

function fornyJwtFejl(errors) {
    return {type: constants.FORNY_JWT_FEJL, alerts: {fejl: errors, info: []}}
}
