import React, {Component} from 'react'

class VersionMain extends Component {

  componentDidMount() {
    const {
      loadForsideFrontendVersion, loadForsideBackendVersion,
      loadUdbyderSystemAdmFrontendVersion, loadUdbyderSystemAdmBackendVersion,
      loadStilserviceAdmFrontendVersion, loadStilserviceAdmBackendVersion,
      loadDataadgangAdmFrontendVersion, loadDataadgangAdmBackendVersion,
      loadRestInstitutionAdmFrontendVersion, loadRestInstitutionAdmBackendVersion,
      loadSupportvaerktoejFrontendVersion, loadSupportvaerktoejBackendVersion
    } = this.props;
    loadForsideFrontendVersion();
    loadForsideBackendVersion();
    loadUdbyderSystemAdmFrontendVersion();
    loadUdbyderSystemAdmBackendVersion();
    loadStilserviceAdmFrontendVersion();
    loadStilserviceAdmBackendVersion();
    loadDataadgangAdmFrontendVersion();
    loadDataadgangAdmBackendVersion();
    loadRestInstitutionAdmFrontendVersion();
    loadRestInstitutionAdmBackendVersion();
    loadSupportvaerktoejFrontendVersion();
    loadSupportvaerktoejBackendVersion();
  }

  render() {
    const {
      forsideFrontendVersion, forsideBackendVersion,
      udbydersystemadmFrontendVersion, udbydersystemadmBackendVersion,
      stilserviceadmFrontendVersion, stilserviceadmBackendVersion,
      dataadgangadmFrontendVersion, dataadgangadmBackendVersion,
      restinstitutionadmFrontendVersion, restinstitutionadmBackendVersion,
      supportvaerktoejFrontendVersion, supportvaerktoejBackendVersion
    } = this.props.version;

    return (
        <div>
          <h1>Tilslutning applikationer</h1>
          <ApplicationStatus
              forsideFrontendVersion={forsideFrontendVersion}
              forsideBackendVersion={forsideBackendVersion}
              udbydersystemadmFrontendVersion={udbydersystemadmFrontendVersion}
              udbydersystemadmBackendVersion={udbydersystemadmBackendVersion}
              stilserviceadmFrontendVersion={stilserviceadmFrontendVersion}
              stilserviceadmBackendVersion={stilserviceadmBackendVersion}
              dataadgangadmFrontendVersion={dataadgangadmFrontendVersion}
              dataadgangadmBackendVersion={dataadgangadmBackendVersion}
              restinstitutionadmFrontendVersion={restinstitutionadmFrontendVersion}
              restinstitutionadmBackendVersion={restinstitutionadmBackendVersion}
              supportvaerktoejFrontendVersion={supportvaerktoejFrontendVersion}
              supportvaerktoejBackendVersion={supportvaerktoejBackendVersion}
          />

          <Version version={forsideFrontendVersion} />
          <Version version={forsideBackendVersion} />
          <Version version={udbydersystemadmFrontendVersion} />
          <Version version={udbydersystemadmBackendVersion} />
          <Version version={stilserviceadmFrontendVersion} />
          <Version version={stilserviceadmBackendVersion} />
          <Version version={dataadgangadmFrontendVersion} />
          <Version version={dataadgangadmBackendVersion} />
          <Version version={restinstitutionadmFrontendVersion} />
          <Version version={restinstitutionadmBackendVersion} />
          <Version version={supportvaerktoejFrontendVersion} />
          <Version version={supportvaerktoejBackendVersion} />
        </div>
    )
  }
}

function ApplicationStatus(props) {
  const versions = Object.values(props);

  if (versions.some(version => !version))
    return (
        <div className="alert alert-info">
          Kunne ikke hente versionsoplysninger for alle systemer.
        </div>
    );

  if (!versions.every(version => version.applicationVersion === versions[0].applicationVersion))
    return (
        <div className="alert alert-danger">
          Alle systemer i Tilslutning har ikke samme version.
        </div>
    );

  return (
      <div className="alert alert-success">
        Alle systemer i Tilslutning har samme version.
      </div>
  );
}

function Version(props) {
  const {version} = props;
  if (!version)
    return null;

  return (
      <div>
        <table className="version-table">
          <tr>
            <th className="version-table-title"><strong>{version.applicationName}</strong></th>
            <th></th>
          </tr>
          <tr>
            <td>Version:</td>
            <td>{version.applicationVersion}</td>
          </tr>
        </table>
      </div>
  )
}

export default VersionMain;
