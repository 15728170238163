import { connect } from 'react-redux'
import ForsideMain from "./ForsideMain";
import * as loginActions from "../actions/login";
import * as forsideActions from "../actions/forside";

const mapStateToProps = (state) => {
  return {
    forside: state.forside,
    login: state.login,
    institutioner: state.login.institutioner,
    restOrganisationer: state.login.restOrganisationer,
    udbydere: state.login.udbydere,
    dagtilbud: state.login.dagtilbud,
    sidsteUdbyderSide: state.login.sidsteUdbyderSide,
    udbyderSide: state.login.udbyderSide,
    notifikationer: state.login.notifikationer
  };
};

const mapDispatchToProps  = (dispatch) => {
  return {
    loginClear: () => dispatch(loginActions.loginClear()),
    selectOrganisation: (event) => dispatch(loginActions.selectOrganisation(event)),
    openSelectOrganisationModal: () => dispatch(loginActions.openSelectOrganisationModal()),
    hentInstitutioner: () => dispatch(loginActions.hentInstitutioner()),
    hentRestOrganisationer: () => dispatch(loginActions.hentRestOrganisationer()),
    hentUdbydere: () => dispatch(loginActions.hentUdbydere()),
    hentDagtilbud: () => dispatch(loginActions.hentDagtilbud()),
    visSessionAlert: () => dispatch(loginActions.visSessionAlert()),
    skjulSessionAlert: () => dispatch(loginActions.skjulSessionAlert()),
    nulstilAlerts: () => dispatch(forsideActions.nulstilAlerts()),
    fornyJwt: () => dispatch(loginActions.fornyJwt()),
    visLoginModal: () => dispatch(loginActions.visLoginModal()),
    skjulLoginModal: () => dispatch(loginActions.skjulLoginModal()),
    basicLogin: (username, password) => dispatch(loginActions.basicLogin(username, password)),
    bekraeftNotifikationsemail: (uuid) => dispatch(loginActions.bekraeftNotifikationsemail(uuid)),
    fornyKontaktperson: (uuid) => dispatch(loginActions.fornyKontaktperson(uuid)),
    hentNotifikationer: () => dispatch(loginActions.hentNotifikationer())
  }
};

const ForsideConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
) (ForsideMain);

export default ForsideConnector;
