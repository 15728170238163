var loginUrlPrefix = process.env.REACT_APP_LOGIN_URL_PREFIX || "";
export var constants = {
  urlLogin: loginUrlPrefix + '/tilslutning/login',
  urlLogout: loginUrlPrefix + '/tilslutning/logout',
  tokenExpiresHeader: 'Auth-Token-Expires',
  urlBruger: '/tilslutningBE/brugeroplysninger',
  apiHentNotifikationer: '/tilslutningBE/notifikationer/hent',
  // State
  loginStateInit: {
    token: null,
    loggedIn: false,
    institutioner: [],
    udbydere: [],
    dagtilbud: [],
    notifikationer: [],
    bruger: {
      brugerid: null,
      navn: null,
      cvr: null,
      organisation: {
        udbyderId: null,
        kode: null,
        navn: null,
        cvr: null,
        pnr: null,
        erPassiveret: null,
        passiveretKommentar: null,
        type: null
      },
      privilegier: null,
      stamdataCvr: null,
      stamdataPnr: null,
      flereOrganisationer: false,
      roller: [],
      readonly: false
    }
  },
  actions: {
    UPDATE_EXPIRES: "UPDATE_EXPIRES"
  },
  roller: {
    udbyderRolle: 'UDBYDER',
    institutionRolle: 'INSTITUTION',
    stilRolle: 'STIL',
    supportRolle: 'SUPPORT',
    ekspertSupportRolle: 'EKSPERT_SUPPORT'
  }
};