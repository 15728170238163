import { connect } from 'react-redux'
import * as loginActions from "../actions/login";
import LoginMain from "./LoginMain.js";

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps  = (dispatch) => {
  return {
    loginClear: () => dispatch(loginActions.loginClear())
  };
};

const LoginConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)((LoginMain));

export default LoginConnector;
