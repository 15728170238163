import {combineReducers} from 'redux'
import {forsideReducer} from "./ForsideReducer.js";
import {loginReducer} from "./LoginReducer.js";
import {versionReducer} from "./VersionReducer";

export default combineReducers({
  forside: forsideReducer,
  login: loginReducer,
  version: versionReducer
});
