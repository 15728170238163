import React, {Component} from "react";
import constants from "./constants";

class Header extends Component {
  render() {
    return (
      <div className="stil-header">
          <header className="stil-header-content">
              <em>Tilslutning</em>
              <a href={constants.basename}>
                  <img src="img/STIL_BLACK_DK.svg" alt="Logo for Styrelsen for it og læring" title="Gå til Tilslutnings forside" />
              </a>
          </header>
      </div>
  );
  }
  }

  export default Header;
