import {connect} from 'react-redux'
import VersionMain from "./VersionMain";
import * as versionActions from '../actions/version'

const mapStateToProps = (state) => {
  return {
    version: state.version
  };
};

const mapDispatchToProps  = (dispatch) => {
  return {
    loadForsideFrontendVersion: () => dispatch(versionActions.loadForsideFrontendVersion()),
    loadForsideBackendVersion: () => dispatch(versionActions.loadForsideBackendVersion()),
    loadUdbyderSystemAdmFrontendVersion: () => dispatch(versionActions.loadUdbyderSystemAdmFrontendVersion()),
    loadUdbyderSystemAdmBackendVersion: () => dispatch(versionActions.loadUdbyderSystemAdmBackendVersion()),
    loadStilserviceAdmFrontendVersion: () => dispatch(versionActions.loadStilserviceAdmFrontendVersion()),
    loadStilserviceAdmBackendVersion: () => dispatch(versionActions.loadStilserviceAdmBackendVersion()),
    loadDataadgangAdmFrontendVersion: () => dispatch(versionActions.loadDataadgangAdmFrontendVersion()),
    loadDataadgangAdmBackendVersion: () => dispatch(versionActions.loadDataadgangAdmBackendVersion()),
    loadRestInstitutionAdmFrontendVersion: () => dispatch(versionActions.loadRestInstitutionAdmFrontendVersion()),
    loadRestInstitutionAdmBackendVersion: () => dispatch(versionActions.loadRestInstitutionAdmBackendVersion()),
    loadSupportvaerktoejFrontendVersion: () => dispatch(versionActions.loadSupportvaerktoejFrontendVersion()),
    loadSupportvaerktoejBackendVersion: () => dispatch(versionActions.loadSupportvaerktoejBackendVersion())
  }
};

const VersionConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
) (VersionMain);

export default VersionConnector;
